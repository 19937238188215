




import { Component } from 'vue-property-decorator';
import 'leaflet/dist/leaflet.css';
import * as L from 'leaflet';
// eslint-disable-next-line
import HeatmapOverlay from 'leaflet-heatmap/leaflet-heatmap.js';
import { Chart, ChartPreview } from '@/models/dashboard';
import ChartBase from '../ChartBase';
import MapChartData from './modelsMap';

@Component
export default class ChartMap extends ChartBase {
  declare chartData: MapChartData;

  mapContainerId = '';

  map: L.Map | null = null;

  featureGroup: L.FeatureGroup = {} as L.FeatureGroup;

  // chartId = 0;

  onInit(): void {
    // if ('id' in this.chart) this.chartId = this.chart.id;
    this.mapContainerId = this.chartUUID ? `map_container_${this.chartUUID}` : 'map_container';

    this.$nextTick(() => {
      this.initMap();

      this.addDataToMap();
    });
  }

  onUpdate(): void {
    this.addDataToMap();
  }

  onChartResize(): void {
    if (this.map) this.map.invalidateSize();
  }

  initMap(): void {
    this.map = L.map(this.mapContainerId);

    this.map.on('moveend', () => {
      console.log('invalidate size');
      this.map!.invalidateSize();
    });

    this.featureGroup = L.featureGroup().addTo(this.map);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map);
  }

  addDataToMap(): void {
    if (this.chartData.mapType === 'points') this.addPoints(this.chartData, this.queryResult);
    if (this.chartData.mapType === 'heatmap') this.addHeatmap(this.chartData, this.queryResult);
  }

  addPoints(chartData: MapChartData, queryResult: any): void {
    this.featureGroup.clearLayers();
    queryResult.forEach((row: any) => {
      L.circleMarker([row[chartData.latField], row[chartData.lonField]]).addTo(this.featureGroup);
    });

    this.map!.fitBounds(this.featureGroup.getBounds());
  }

  addHeatmap(chartData: MapChartData, queryResult: any): void {
    console.log('add heatmap');

    this.featureGroup.clearLayers();

    const heatmapLayer = new HeatmapOverlay({
      radius: 5,
      maxOpacity: 0.8,
      scaleRadius: true,
      useLocalExtrema: true,
      latField: chartData.latField,
      lngField: chartData.lonField,
      valueField: chartData.intensityField,
    });

    heatmapLayer
      .addTo(this.featureGroup)
      .setData({
        max: 1000,
        data: queryResult,
      });

    const bounds = L.latLngBounds([
      [queryResult[0][chartData.latField], queryResult[0][chartData.lonField]],
      [queryResult[0][chartData.latField], queryResult[0][chartData.lonField]],
    ]);
    queryResult.forEach((row: any) => {
      bounds.extend([row[chartData.latField], row[chartData.lonField]]);
    });

    this.map!.fitBounds(bounds);
  }
}
